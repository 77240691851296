import { render, staticRenderFns } from "./templateManage.vue?vue&type=template&id=c9197b72&scoped=true&"
import script from "./templateManage.vue?vue&type=script&lang=js&"
export * from "./templateManage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c9197b72",
  null
  
)

export default component.exports