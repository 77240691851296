<template>
  <el-dialog
    :title="dialogType === 'edit' ? '修改模板' : '新增模板'"
    :visible.sync="dialogVisible"
    append-to-body
    :close-on-click-modal="false"
    @close="$emit('update:dialogVisible', false)"
    width="50%"
    class="item-dialog"
  >
    <div class="dialog-content">
      <el-form
        ref="form"
        :model="form"
        size="mini"
        :rules="rules"
        :label-position="'right'"
        label-width="auto"
      >
        <el-form-item label="选择器类型" prop="selectType">
          <el-select
            v-model="form.selectType"
            style="width: 100%"
            placeholder="请选择选择器类型"
            size="mini"
            filterable
            @change="handleChange"
          >
            <el-option
              v-for="(item, index) in selectTypes"
              :key="index"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分页方式" prop="pageType">
          <el-select
            v-model="form.pageType"
            style="width: 100%"
            placeholder="请选择分页方式"
            size="mini"
            filterable
            @change="handleChange"
          >
            <el-option
              v-for="(item, index) in pageTypes"
              :key="index"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="列表页表达式" prop="selectUrlList">
          <el-input
            v-model="form.selectUrlList"
            clearable
            placeholder="请输入列表页表达式"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="开始分页表达式" prop="selectPage">
          <el-input
            v-model="form.selectPage"
            clearable
            placeholder="请输入开始分页表达式"
          ></el-input>
        </el-form-item>
        <el-form-item label="结束分页表达式" prop="selectPageStop">
          <el-input
            v-model="form.selectPageStop"
            clearable
            placeholder="请输入结束分页表达式"
          ></el-input>
        </el-form-item>
        <el-form-item label="翻页url开始拼接数" prop="selectStart">
          <el-input
            v-model="form.selectStart"
            clearable
            placeholder="请输入翻页url开始拼接数"
          ></el-input>
        </el-form-item>
        <el-form-item label="列表页总页数" prop="selectTotalPage">
          <el-input
            v-model="form.selectTotalPage"
            clearable
            placeholder="请输入列表页总页数"
          ></el-input>
        </el-form-item>
        <el-form-item label="列表日期表达式" prop="selectDatePosted">
          <el-input
            v-model="form.selectDatePosted"
            clearable
            placeholder="请输入列表日期表达式"
          ></el-input>
        </el-form-item>
        <el-form-item label="文章标题" prop="selectTitle">
          <el-input
            v-model="form.selectTitle"
            clearable
            placeholder="请输入文章标题"
          ></el-input>
        </el-form-item>
        <el-form-item label="文章内容" prop="selectText">
          <el-input
            v-model="form.selectText"
            clearable
            placeholder="请输入文章内容"
          ></el-input>
        </el-form-item>
        <el-form-item label="时间插件" prop="selectPublishTime">
          <el-input
            v-model="form.selectPublishTime"
            clearable
            placeholder="请输入时间插件"
          ></el-input>
        </el-form-item>
        <el-form-item label="文章来源" prop="selectArticleSource">
          <el-input
            v-model="form.selectArticleSource"
            clearable
            placeholder="请输入文章来源"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remarks">
          <el-input
            v-model="form.remarks"
            clearable
            placeholder="请输入备注"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="$emit('update:dialogVisible', false)"
        >取 消</el-button
      >
      <el-button size="small" type="primary" @click="saveOnClick"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
export default {
  name: 'addWrongWord',
  props: {
    dialogVisible: {
      type: Boolean,
      require,
    },
    errorData: {
      type: Object,
      default: () => ({}),
    },
    dialogType: {
      type: String,
      require,
    },
  },
  data() {
    return {
      selectTypes: [
        { name: 'XPATH', value: 'XPATH' },
        { name: 'CSS', value: 'CSS' },
        { name: 'REGEX', value: 'REGEX' },
      ],
      pageTypes: [
        { name: '普通加载', value: 1 },
        { name: '动态加载', value: 2 },
      ],

      form: {
        selectType: '',
        pageType: '',
        selectUrlList: '',
        selectPage: '',
        selectPageStop: '',
        selectStart: '',
        selectTotalPage: '',
        selectDatePosted: '',
        selectPublishTime: '',
        selectArticleSource: '',
        selectText: '',
        remarks:''
      },
      rules: {
        selectType: [{ required: true, message: '请选择选择器类型' }],
        pageType: [{ required: true, message: '请选择分页方式' }],
        selectUrlList: [{ required: true, message: '请输入列表页表达式' }],
        selectPage: [{ required: true, message: '请输入开始分页表达式' }],
        selectStart: [{ required: true, message: '请输入翻页url开始拼接数' }],
        selectTotalPage: [{ required: true, message: '请输入列表页总页数' }],
        selectPageStop: [{ required: true, message: '请输入结束分页表达式' }],
        selectDatePosted: [{ required: true, message: '列表日期表达式' }],
        selectTitle: [{ required: true, message: '请输入文章标题' }],
        selectText: [{ required: true, message: '请输入文章内容' }],
        selectPublishTime: [{ required: true, message: '请输入发布时间' }],
        selectArticleSource: [{ required: true, message: '请输入文章来源' }],
      },
    };
  },
  mounted() {
    if (this.dialogType == 'edit') {
      Object.assign(this.form, this.errorData);
    }
  },
  methods: {
    handleChange(e) {
      this.$forceUpdate();
    },
    addModel() {
      this.$api.dataAcquisition.addModel(this.form).then((res) => {
        if (res.code == 200) {
          this.$message({
            message: '添加成功',
            type: 'success',
          });
          this.$emit('update:dialogVisible', false);
          this.$emit('update');
        }
      });
    },
    editModel() {
      this.$api.dataAcquisition.editModel(this.form).then((res) => {
        if (res.code == 200) {
          this.$message({
            message: '修改成功',
            type: 'success',
          });
          this.$emit('update:dialogVisible', false);
          this.$emit('update');
        }
      });
    },
    saveOnClick() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.dialogType == 'edit') {
            this.editModel();
          }else{
            this.addModel();
          }
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog-content {
  width: 90%;
  margin: 0 auto;
}
::v-deep .el-dialog .el-dialog__body {
  height: 400px;
  overflow-y: scroll;
}
</style>
