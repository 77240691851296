<template>
  <div class="main-container">
    <div class="pt-wrapper">
      <!-- 搜索 -->
      <div class="hd-search">
        <table-search
          :model="searchParams"
          label-width="80px"
          :isClear="true"
          :flex="'4'"
          @search="searchFn"
        >
          <search-item default label="">
            <el-input
              v-model="searchParams.id"
              placeholder="模版ID"
              clearable
            />
          </search-item>
        </table-search>
      </div>
      <div class="bd-content">
        <!-- 操作按钮 -->
        <div class="table-opt">
          <el-button
            type="primary"
            class="el-icon-circle-plus-outline"
            @click="addItem"
          >
            新 增
          </el-button>
        </div>
        <!-- 表格 -->
        <pageTable
          ref="table"
          :columns="columnList"
          :params="searchParams"
          :api-function="this.$api.dataAcquisition.queryModelPageList"
        >
          <template slot="pageType" slot-scope="scope">
            <div class="data-operate">
              <span v-if="scope.row.pageType === 1">普通加载</span>
              <span v-if="scope.row.pageType === 2">动态加载</span>
            </div>
          </template>
          <template slot="options" slot-scope="scope">
            <div class="data-operate">
              <span class="op-blue" @click="change(scope.row)"> 编辑 </span>
              <span class="op-red" @click="del(scope.row.id)"> 删除 </span>
            </div>
          </template>
        </pageTable>
      </div>
    </div>
    <addTemplate
      v-if="dialogVisible"
      :dialogType="dialogType"
      :dialogVisible.sync="dialogVisible"
      :errorData="form"
      @update="searchFn()"
    >
    </addTemplate>
  </div>
</template>

<script>
import addTemplate from './components/addTemplate.vue';

export default {
  components: {
    addTemplate,
  },

  name: 'templateManage',
  data() {
    return {
      selectData: [],
      searchParams: {
        id: '', //任务名称
      },
      columnList: [
        {
          label: '模版ID',
          prop: 'id',
        },
        {
          label: '选择器类型',
          prop: 'selectType',
        },
        {
          label: '分页方式',
          show: 'template',
          prop: 'pageType',
        },
        {
          label: '列表页表达式',
          prop: 'selectUrlList',
        },
        {
          label: '开始分页表达式',
          prop: 'selectPage',
        },
        {
          label: '结束分页表达式',
          prop: 'selectPageStop',
        },
        {
          label: '翻页url开始拼接数',
          prop: 'selectStart',
        },
        {
          label: '列表页总页数',
          prop: 'selectTotalPage',
        },

        {
          label: '列表日期表达式',
          prop: 'selectDatePosted',
        },
        {
          label: '文章标题',
          prop: 'selectTitle',
          width: '100',
        },
        {
          label: '文章内容',
          prop: 'selectText',
          width: '180',
        },
        {
          label: '发布时间',
          prop: 'selectPublishTime',
          width: '180',
        },
        {
          label: '文章来源',
          prop: 'selectArticleSource',
          width: '180',
        },
        {
          label: '备注',
          prop: 'remarks',
        },
        
        {
          label: '操作',
          prop: 'options',
          show: 'template',
          width: '120',
        },
      ],
      statusList: [
        { label: '未开始', value: '1' },
        { label: '进行中', value: '2' },
        { label: '已完成', value: '3' },
      ],
      form: {},
      dialogVisible: false,
    };
  },
  methods: {
    // 操作栏
    handleoperate(row, status) {},
    addItem() {
      this.title = '新增错词';
      this.dialogType = 'add';
      this.dialogVisible = true;
    },
    change(row) {
      this.dialogType = 'edit';
      this.dialogVisible = true;
      this.form.questionType = [];
      this.form.questionType.push(row.errorType);
      this.form.questionType.push(row.subErrorType);
      Object.assign(this.form, row);
    },
    del(id) {
      this.$msg.confirm('确认删除？').then(() => {
        this.$api.dataAcquisition
          .delModel({
            id: id,
          })
          .then((res) => {
            if (res.code == 200) {
              this.$message({
                message: "删除成功",
                type: 'success',
              });
             this.searchFn()
            }
          });
      });
    },
    searchFn() {
      const tableRef = this.$refs.table;
      tableRef.getPageDataOfAction('search');
    },
  },
};
</script>
<style lang="scss" scoped></style>
